<template>
  <section title="">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col md="9">
                <h2>
                  Usuarios
                </h2>
              </b-col>
              <b-col md="3">
                <b-button
                  variant="outline-success"
                  class="adicionarUsuario"
                  @click="adicionar()"
                >
                  <feather-icon
                    icon="UserPlusIcon"
                    class="mr-50"
                  />
                  Adicionar Usuario
                </b-button>
              </b-col>
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        v-b-modal.popupEditar
                        variant="outline-info"
                        class="btn-icon rounded-circle"
                        @click="EditUser(item)"
                      >
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        class="btn-icon rounded-circle ml-1"
                        @click="DeletarDados(item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'Permissão'">
                      <b-badge
                        :variant="permissionBadge(item.role_user).cor"
                      >
                        {{ permissionBadge(item.role_user).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>

import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      url: 'user/all',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'nome',
          name: 'name',
          orderable: false,
        },
        {
          label: 'username',
          name: 'username',
          orderable: false,
        },
        {
          label: 'E-mail',
          name: 'email',
          orderable: false,
        },
        {
          label: 'Permissão',
          orderable: false,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
    }
  },
  async created() {
    this.BuscarDados(this.url)
    this.$loading(true)
  },
  methods: {
    EditUser(item) {
      this.$router.push({
        name: 'app-usuario-editar',
        params: {
          dados: item,
        },
      })
    },
    permissionBadge(item) {
      let cor = 'secondary'
      let msg = 'Não definido'
      item.forEach(roles => {
        if (roles.role.slug === 'admin') {
          cor = 'light-primary'
          msg = 'Administrador'
        }
        if (roles.role.slug === 'vendedor') {
          cor = 'light-warning'
          msg = 'Vendedor'
        }
        if (roles.role.slug === 'caixa') {
          cor = 'light-success'
          msg = 'Caixa'
        }
      })
      return { cor, msg }
    },
    adicionar() {
      this.$router.push('/usuarios/adicionar')
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              this.$loading(true)
              await this.$http.delete(`user/excluir/${item.id}`)
              this.$toast.success('Usuário removido com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },

}

</script>

<style lang="scss" scoped>

.adicionarUsuario {
  width: 100%;
  margin-bottom: 10px;
}
</style>
